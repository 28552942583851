import './App.css';
import Hero from './components/Hero/Hero';
import Program from './components/programs/program';
import Reason from './components/Reasons/reason';
import Plans from './components/Plans/plans';
import Testimonial from './components/Testimonials/testimonial';
import Join from './components/Join/join';
import Footer from './components/Footer/footer';
function App() {
  return (
    <div className="App">
         <Hero/>
         <Program/>
         <Reason/>
         <Plans/>
         <Testimonial/>
         <Join/>
         <Footer/>
         
    </div>
  );
}

export default App;
